import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import CabecalhoAdminCompleto from './CabecalhoAdminCompleto'
import AdminPortfolio from './cadastro/portfolio/AdminPortfolio'
import { auth } from './../fbConfig'
import AdminNovoCadastroPortfolio from './cadastro/portfolio/AdminNovoCadastroPortfolio'
import RodapeAdmin from './RodapeAdmin'
import AdminEditarCadastroPortfolio, { privateUrls } from './cadastro/portfolio/AdminEditarCadastroPortfolio'
import AdminConquistas from './cadastro/conquistas/AdminConquistas'
import AdminEditarCadastroConquistas, { privateUrlsConquista } from './cadastro/conquistas/AdminEditarCadastroConquistas'

class Admin extends Component {
    constructor(props) {
        super(props)

        this.state = {
            estaLogando: true,
            estaAutendicado: false,
            user: null,

        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(user => {
            this.setState({
                estaLogando: false,
                estaAutendicado: !!user,
                user
            })
        })
    }

    render() {
        if (this.state.estaLogando) {
            return <p>Aguarde...</p>
        }
        if (!this.state.estaAutendicado) {
            return <Redirect to='/login' />
        }
        return (
            <div>
                <h2>Painel Administrativo</h2>
                    <CabecalhoAdminCompleto />

                    <Route path={`/`} component={CabecalhoAdminCompleto} />
                    <Route path={'/admin/portfolio'} component={AdminPortfolio} />
                    <Route path={'/admin/portfolio-novo'} component={AdminNovoCadastroPortfolio} />
                    <Route path={privateUrls.edit.path}
                        render={(props) => <AdminEditarCadastroPortfolio {...props} />} />
                    <Route path={'/admin/conquistas'} component={AdminConquistas} />
                    <Route path={privateUrlsConquista.edit.path}
                        render={(props) => <AdminEditarCadastroConquistas {...props} />} />
                    <RodapeAdmin />
            </div>
        )
    }
}

export default Admin