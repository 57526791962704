
const Rebase = require('re-base')
const firebase = require('firebase')



  const config = {
    apiKey: "AIzaSyBi1cpaa3szU1fGcUAh69kl28vKjuKXLj8",
    authDomain: "magalhaesdb.firebaseapp.com",
    databaseURL: "https://magalhaesdb.firebaseio.com",
    projectId: "magalhaesdb",
    storageBucket: "magalhaesdb.appspot.com",
    messagingSenderId: "286456720472",
    appId: "1:286456720472:web:360b7e2a768f56c8c4ee2d",
    measurementId: "G-M7SS5406WQ"
  }

  const app = firebase.initializeApp(config)
  const fbConfig = Rebase.createClass(app.database())

  export const firebaseDatabase = app.database();

  export const storage = app.storage()
  export const auth = app.auth()

  export default fbConfig