import React from 'react'

const ItemPortfolio = props => {
    return (
        <div className='col-sm-4 propThumbnail'>
            <div className='thumbnail'>
                <img src={props.conteudo.imagemPortfolio} alt='' width='50px' height='50px' />
                <p><strong>{props.conteudo.titulo}</strong></p>
            </div>
        </div>
    )
}

export default ItemPortfolio