import React from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        sidebar: state.Detalhes.sidebar
    };
}

const Legislacao = ({ sidebar }) => {

    const isOn = sidebar
    return (
        <div>
            <div id='legislacao' className={isOn ? 'container-fluid  page-content p-5 content' : 'container-fluid  page-content p-5 content active'}>
                <div className='row'>
                    <div className='col-lg-7'>
                        <h2>Legislação e incentivos</h2><br />
                        <h3><b>Agora que já abordamos todos os aspectos técnicos envolvidos na produção de energia solar, vamos entender melhor por que essa é uma fonte de energia cada vez mais procurada no Brasil, as mudanças na legislação e incentivos do poder público.</b></h3><br />
                        <div className='comoFuncionaDetalhes'>
                            <p>Nos últimos anos ocorreram diversas mudanças vantajosas para quem produz energia solar. A principal delas foi o surgimento do sistema de compensação, em que o consumidor usa a rede da concessionária para lançamento direto da energia produzida em excesso (não utilizada no momento de sua geração).</p>
                            <p>Esse excedente é transformado em crédito, que posteriormente será compensado com os débitos dessa energia no horário em que não houver produção. Essa utilização foi possível a partir da Resolução normativa nº 482 de 2012 da Agência Nacional de Energia Elétrica (ANEEL), que estabeleceu:</p>
                            <p>Com objetivo de incentivar o mercado, a Agência Nacional de Energia Elétrica (ANNEL), publicou a Resolução Normativa nº 687 de 2015, que atualiza a resolução de 2012 (nº 482), descrita acima, para:</p>
                            <li>Validade de 60 meses para os créditos produzidos;</li>
                            <li>Microgeração até 75kW e minigeração maior que 75kW e igual ou menor que 5MW;</li>
                            <li>Possibilidade de utilizar os créditos gerados em outras unidades consumidoras desde que vinculadas a um mesmo CPF, registrados na mesma distribuidora de energia;</li>
                            <li>Prazo de conexão do sistema com a concessionária de até 120 dias. Ou seja, a partir do momento que o projetista registra a solicitação na concessionária, ela terá um prazo de até 120 dias para conectar o sistema, desde que esse esteja todo em conformidade com as exigências.</li>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(
    mapStateToProps,
)(Legislacao);