import React, { Component } from 'react'


class RodapeAdmin extends Component {

    render() {
        return (
            <footer className='container-fluid text-center'>
                    <a href='#myPage' title='Para o Topo'>
                        <span className='glyphicon glyphicon-chevron-up'></span>
                    </a>
                    
                    <p>Copyright © 2020 Magalhães Engenharia Elétrica. Todos os direitos reservados. Desenvoldido por <a href='https://www.issidios.com' title='Visite Issidios Tecnologia'>Issidios Tecnologia</a></p>
                    <div>Ícones feitos por <a href="https://www.flaticon.com/br/autores/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/br/" title="Flaticon">www.flaticon.com</a></div>
            </footer>
        )
    }
}

export default RodapeAdmin