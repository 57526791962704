import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class CabecalhoLoja extends Component {
    render() {
        return (
            <div id="myCarousel" className="carousel slide" data-ride="carousel">


                <nav className='navbar navbar-default navbar-fixed-top'>
                    <div className='container'>
                        <div className='navbar-header'>
                            <button type='button' className='navbar-toggle' data-toggle='collapse' data-target='#myNavbar'>
                                <span className='icon-bar'></span>
                                <span className='icon-bar'></span>
                                <span className='icon-bar'></span>
                            </button>
                            <Link className="navbar-brand" to='/'>
                                <img src='logo_v.png' alt='' width='340px' height='45px' />
                            </Link>
                        </div>
                        <div className='collapse navbar-collapse' id='myNavbar'>
                            <ul className='nav navbar-nav navbar-right'>

                                <li><Link to='/loja/carrinho' className='ml-auto'><i className='fas fa-cart-plus'/></Link></li>

                            </ul>
                        </div>
                    </div>
                </nav>
            </div >
        );
    }
}

export default CabecalhoLoja;