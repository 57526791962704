import React from 'react'
import { Link } from 'react-router-dom'


const CabecalhoAdminCompleto = props => {
    return (
        <div id='myPage'>

            <nav className='navbar navbar-default navbar-fixed-top'>
                <div className='container'>
                    <div className='navbar-header'>
                        <button type='button' className='navbar-toggle' data-toggle='collapse' data-target='#myNavbar'>
                            <span className='icon-bar'></span>
                            <span className='icon-bar'></span>
                            <span className='icon-bar'></span>
                        </button>
                        <Link to='/' className="navbar-brand">
                            <img src='logo_v.png' alt='' width='340' height='45' />
                        </Link>
                    </div>
                    <div className='collapse navbar-collapse' id='myNavbar'>
                        <ul className='nav navbar-nav navbar-right'>
                            <li className='dropdown' id='cadastroMenu'>
                                <a className="dropdown-toggle" data-toggle="dropdown">CADASTRO <span className='caret'></span></a>
                                <ul className="dropdown-menu">
                                    <li><Link to='/admin/portfolio'>PORTFÓLIO</Link></li>
                                    <li><Link to='/admin/conquistas'>CONQUISTAS</Link></li>
                                </ul>
                            </li>
                            <li><Link to='/'>VOLTAR</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default CabecalhoAdminCompleto