import React, { Component } from 'react'
import CaroselCabecalho from './CaroselCabecalho'
import { Link } from 'react-router-dom'

class Cabecalho extends Component {
    render() {
        return (
            <div>
                <nav className='navbar navbar-default navbar-fixed-top'>
                    <div className='container'>
                        <div className='navbar-header'>
                            <button type='button' className='navbar-toggle' data-toggle='collapse' data-target='#myNavbar'>
                                <span className='icon-bar'></span>
                                <span className='icon-bar'></span>
                                <span className='icon-bar'></span>
                            </button>
                            <a className="navbar-brand" href='#myPage'>
                                <img src='logo_v.png' alt='' width='340px' height='45px' />
                            </a>
                        </div>
                        <div className='collapse navbar-collapse' id='myNavbar'>
                            <ul className='nav navbar-nav navbar-right'>
                                <li><a href='#about'>INÍCIO</a></li>
                                <li><a href='#services'>SERVIÇOS</a></li>
                                <li><a href='#contact'>FALE CONOSCO</a></li>
                                <li><a href='#portfolio'>PORTFÓLIO</a></li>
                                <li><a id='separador'><span>|</span></a></li>
                                <li><Link to='/loja'>LOJA</Link></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div id='myPage'>
                    <CaroselCabecalho />
                </div>
            </div>
        )
    }
}

export default Cabecalho