import React from 'react';
import { connect } from 'react-redux';

import * as DetalhesActions from '../store/actions/Detalhes'



function mapStateToProps(state) {
    return {
        sidebar: state.Detalhes.sidebar
    };
}

const EnergiaSolar = ({sidebar, dispatch}) => {

    const isOn = sidebar;
        return (
            <div>
                <div id='' className={isOn ? 'container-fluid page-content p-5 content' : 'container-fluid page-content p-5 content active'}>
                    <button id='function' type="button" className="btn btn-light rounded-pill shadow-sm px-4 mb-4 botaoDetalhes" onClick={() => dispatch(DetalhesActions.toggle(!isOn))}>
                        <i className="fa fa-bars mr-2"></i>
                    </button>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <h2>Energia Solar</h2><br />
                            <h3><b>Residencial</b></h3><br />
                            <h3>Reduza sua conta de luz e valorize seu imóvel com Energia Solar.</h3>
                            <p>Os sistemas de energia solar comercializados e instalados pela Magalhães Engenharia unem qualidade e eficiência com o melhor custo-benefício do mercado. Usando equipamentos modernos e com design sofisticado que vão gerar economia de até 95% na sua conta de energia, valorizando ainda mais seu imóvel. Conte com a agilidade de uma assistência técnica especializada, e uma equipe de profissionais que atuarão em todas as fases de sua usina com toda a segurança que sua família merece.</p>
                            <h3><b>Comercial e  industrial</b></h3><br />
                            <h3>Reduza os custos fixos de seu negócio e aumente sua lucratividade com energia solar.</h3>
                            <p>Ser empresário no Brasil é um desafio constante, além das dificuldades da atividade, o empresário brasileiro também precisa lidar com altas taxas de juros, incertezas politicas e crises econômicas, o que desafia o empreendedor a estar sempre à procura de oportunidades de investimentos que reduzam custos e otimizem o uso de recursos. Se você quer mais economia e maior eficiência em seus negócios, as soluções em Energia Solar da Magalhães Engenharia são ideais para fazer o seu negócio gerar energia limpa e crescer sem sofrer com os altos custos tarifários de energia elétrica.</p>
                            <h3><b>Agronegócio</b></h3><br />
                            <h3>Mais sustentabilidade para o planeta e mais economia para seu bolso! O agronegócio tem as melhores taxas de financiamento disponíveis no mercado.</h3>
                            <p>O Sol e o agronegócio sempre andaram juntos. Agora, mais do que nunca: o campo já responde por quase 9% dos investimentos em energia solar aqui no Brasil. O motivo você já conhece: economia, sustentabilidade e eficiência. Na agricultura e na pecuária, sistemas fotovoltaicos têm sido responsáveis por reduzir custos, permitir novos investimentos e impulsionar a competitividade de quem escolhe o Sol como fonte de energia elétrica. O impacto econômico é direto em atividades como irrigação de lavouras, bombeamento, refrigeração de insumos como o leite e manejo do gado.</p>

                            <br />
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default connect(mapStateToProps)(EnergiaSolar);