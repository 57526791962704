import React, { Component} from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import { Link } from 'react-router-dom'
import FormularioContato from './FormularioContato'

const options = [
    { value: 'Pontes e Lacerda - Matriz', label: 'Pontes e Lacerda - Matriz' },
    { value: 'Vilhena', label: 'Vilhena' }
]


class Contato extends Component {
    constructor() {
        super();
    }

    render() {


        return (

            /* Container (Contact Section) */
            <div id='contact' className='container-fluid bg-grey'>
                <h2 className='text-center'>Fale Conosco</h2>
                <h3 className='text-center'>Responderemos o mais rapido possivel</h3><br />
                <div className='row'>
                    
                    <div className='col-sm-6 textoContato'>
                        <p>Para atendê-lo melhor, disponibilizamos esse canal para informações e atendimento.</p>
                        <p>Mas se você está interessando em um <b>orçamento</b> para instalar um de nossos sistemas de energia solar, temos um espaço dedicado para sua solicitação.</p>
                        <Link className="btn btn-default btnContato" to='/orcamento'>
                            Requisitar orçamento
                    <i className="glyphicon glyphicon-chevron-right logo-small" />
                        </Link>
                        <br />
                        <p>Você também pode nos achar em nossas redes sociais.</p>
                        <div className='text-center separadorContato '></div>
                        <div className='text-center midia'>
                            <a href="https://www.facebook.com/engenhariamagalhaes" title="https://www.facebook.com/engenhariamagalhaes">
                                <img src='./imagens/logo/Facebook.png' alt='' />
                            </a>
                            <a href="https://www.instagram.com/engenhariamagalhaes/?hl=pt-br" title="https://www.instagram.com/engenhariamagalhaes/?hl=pt-br">
                                <img src='./imagens/logo/Instagram.png' alt='' />
                            </a>
                        </div>
                    </div>
                    <FormularioContato />
                </div>
                <br />
                <h2 className='text-center'>Venha nos visitar</h2>
                <div >
                    <Tabs>

                        <TabList>
                            <Tab>Pontes e Lacerda</Tab>
                            <Tab>Vilhena</Tab>
                        </TabList>

                        <TabPanel>
                            <p ><span className='glyphicon glyphicon-map-marker'></span>Avenida Florispina Azambuja, 1122, Centro - Pontes e Lacerda - MT</p>
                            <p ><span className='glyphicon glyphicon-phone'></span> (65) 9 9330-9515 </p>
                            <p ><span className='glyphicon glyphicon-earphone'></span> (65) 3266-5331 </p>
                            <p ><span className='glyphicon glyphicon-envelope'></span> vendas@magalhaesengenharia.com</p>
                            <p ><span className='glyphicon glyphicon-time'></span> Segunda à Quinta - 07:00 - 18:00 / Sexta - 07:00 - 17:00</p>
                            <Map center={[-15.227965, -59.335171]} zoom={15}>
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                                />
                                <Marker position={[-15.227965, -59.335171]}>
                                    <Popup>Magalhães Engenharia Eletrica<br />
                                        <a href="https://www.google.com/maps/search/-15.227965,+-59.335171?sa=X&ved=2ahUKEwjC7-LBxJ3sAhUpIbkGHcmOCxMQ8gEwAHoECAEQAQ" >
                                            Venha nos visitar.</a></Popup>
                                </Marker>
                            </Map>


                        </TabPanel>
                        <TabPanel>
                            <p ><span className='glyphicon glyphicon-map-marker'></span>Rua Geraldo Magela, 146, Centro - Vilhena - RO</p>
                            <p ><span className='glyphicon glyphicon-phone'></span> (65) 9 9330-9515 </p>
                            <p ><span className='glyphicon glyphicon-earphone'></span> (69) 3321-1650 </p>
                            <p ><span className='glyphicon glyphicon-envelope'></span> vendas.vlh@magalhaesengenharia.com</p>
                            <p ><span className='glyphicon glyphicon-time'></span> Segunda à Quinta - 07:00 - 18:00 / Sexta - 07:00 - 17:00</p>
                            <Map center={[-12.7411408, -60.1365177]} zoom={15}>
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                                />
                                <Marker position={[-12.7411408, -60.1365177]}>
                                    <Popup>Magalhães Engenharia Eletrica<br />
                                        <a href="https://www.google.com/maps/search/-12.7411408,+-60.1365177?sa=X&ved=2ahUKEwi65PLMxJ3sAhU4H7kGHVPQA-cQ8gEwAHoECAEQAQ" >
                                            Venha nos visitar.</a></Popup>
                                </Marker>
                            </Map>
                        </TabPanel>
                    </Tabs>
                </div>
                <br />

            </div>
        )
    }
}

export default Contato