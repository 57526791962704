import React, { Component } from 'react'

class Parceiro extends Component {

    render() {


        return (
            <div className='container-fluid'>
                <h2>INTEGRADOR WEG SOLAR OFICIAL</h2>
                <div className='parceirosLogo row'>
                    <div className='col-sm-4'>
                        <a href='https://www.weg.net/solar'>
                            <img src="./imagens/logo-parceiros/weg-logo.png" alt="WEG" width='100px' />
                        </a>
                    </div>
                    <div className='col-sm-8 textoInicio'>
                        <br />
                        <p>A disponibilidade de luz solar em nossa região é propicia para geração elétrica, aliás, utilizar-se do Sol como fonte de Energia é uma iniciativa importante em sustentabilidade, e direciona o futuro da energia elétrica no mundo.</p>
                        <p>Para isso a Magalhães Engenharia Elétrica tornou-se Integradora <a href='https://www.weg.net/solar'>WEG S.A.</a>, aliando-se a uma empresa pioneira no fornecimento de soluções em energias renováveis no Brasil, presente em 26 países e que utiliza toda a sua experiência e tecnologia em fontes de energia solar para fornecer soluções completas e com tecnologia de ponta. Além disso, também dispomos de uma equipe técnica composta por Engenheiros, Eletrotécnicos e Eletricistas devidamente capacitados para instalação de sistemas fotovoltaicos residenciais, comerciais e industriais de pequeno, médio e grande porte fornecendo kits completos de equipamentos para você aproveitar todos os benefícios da energia solar.</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default Parceiro