import React, { Component } from 'react'
import CountTo from 'react-count-to';
import { Link } from 'react-router-dom';
import Powerslap from './../video/Magalhães Engenharia PL.mp4'


class CaroselCabecalho extends Component {

    render() {

        return (
            <div id="myCarousel" className="carousel slide" data-ride="carousel">


                <div className="carousel-inner">
                    <div className="item active">
                        <div className='imagemCabecalho'>
                            <video autoPlay loop muted preload='auto' poster='./imagens/Magalhães Engenharia PL.mp4_snapshot_00.00_[2020.11.10_13.23.42].jpg' className='videoCabecalho'>
                                <source src={Powerslap} type='video/mp4'/>
                            </video>
                        </div>

                        <div className="carousel-caption">
                            
                            <Link className="btn btn-default btnCabecalho" to='/orcamento'>Faça um orçamento</Link>
                            <div className="vantagens">
                                <div className="row invisivel vantagemItem">
                                    <div className="col-sm-3 vantagemItemUm">
                                        <img src="./imagens/cabecalho/018-energy saving.png" alt="Item 1" className='iconeCabecalho' />
                                    </div>
                                    <div className="col-sm-3 vantagemTexto">
                                        <p className='vanUmTitulo'>A partir de</p>
                                        <p className='vanUmValor'><b><CountTo from={0} to={3} speed={1800}></CountTo> anos</b></p>
                                        <p className='vanUmUnidade'>para o retorno do investimento</p>
                                    </div>
                                    <div className="col-sm-3 vantagemItemUm">
                                        <img src="./imagens/cabecalho/038-solar energy.png" alt="Item 1" className='iconeCabecalho' />
                                    </div>
                                    <div className="col-sm-3 vantagemTexto">
                                        <p className='vanUmTitulo'>Até</p>
                                        <p className='vanUmValor'><b><CountTo from={0} to={95} speed={1800}></CountTo>%</b></p>
                                        <p className='vanUmUnidade'>de economia na energia</p>
                                    </div>
                                    <div className="col-sm-3 vantagemItemUm">
                                        <img src="./imagens/cabecalho/011-solar energy.png" alt="Item 1" className='iconeCabecalho' />
                                    </div>
                                    <div className="col-sm-3 vantagemTexto">
                                        <p className='vanUmTitulo'>Até</p>
                                        <p className='vanUmValor'><b><CountTo from={0} to={30} speed={1800}></CountTo> anos</b></p>
                                        <p className='vanUmUnidade'>de garantia</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div >
        )
    }
}

export default CaroselCabecalho