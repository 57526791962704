import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import CabecalhoAdmin from './CabecalhoAdmin'
import { auth } from './../fbConfig'

class LoginAdmin extends Component {
    constructor(props) {
        super(props)

        this.state = {
            logado: false,
            estaLogando: false,
            erro: false
        }

        this.email = null
        this.senha = null

        this.fazerLogin = this.fazerLogin.bind(this)
    }

    fazerLogin() {
        this.setState({ estaLogando: true, erro: false })
        auth.signInWithEmailAndPassword(this.email.value, this.senha.value)
            .then(user => {
                this.setState({ logado: true })
            })
            .catch(err => {
                this.setState({ erro: true, estaLogando: false, logado: false })
            })
    }

    render() {
        if (this.state.logado) {
            return <Redirect to='/admin' />
        }
        return (
            <div>
                <CabecalhoAdmin />
                <div className='bg-grey'>
                    <div className='container-fluid loginAdmin'>
                        <h1>Login</h1>
                        <form className="px-4 py-3">
                            <div className="form-group">
                                <label htmlFor="exampleDropdownFormEmail1">Endereço de E-mail</label>
                                <input type="email" name='email' ref={ref => this.email = ref} className="form-control" id="exampleDropdownFormEmail1" placeholder="email@exemplo.com" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleDropdownFormPassword1">Senha</label>
                                <input type="password" name='senha' ref={ref => this.senha = ref} className="form-control" id="exampleDropdownFormPassword1" placeholder="Senha" />
                                {this.state.erro && <small className="form-text text-muted">E-mail e/ou senha inválidos</small>}
                            </div>

                            <button type="button" disabled={this.state.estaLogando} className="btn btn-primary" onClick={this.fazerLogin}>Entrar</button>
                        </form>
                        <div className="dropdown-divider"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoginAdmin