import React, { useState } from 'react'
import axios from 'axios'
import InputMask from 'react-input-mask';


function FormularioContato() {
    const [campos, setCampos] = useState({
        nome: "",
        email: "",
        celular: "",
        cidade: "",
        comentario: "",
        selectedOption: null,
        enviarEmail: "",
        unidade: ""
    });

    function handleInputChange(event) {
        campos[event.target.name] = event.target.value;
        setCampos(campos);
    }

    function send() {
        const formData = new FormData();
        Object.keys(campos).forEach(key => formData.append(key, campos[key]));
        axios.post('https://backendmagalhaes.herokuapp.com/enviarContato',
            formData,
            {
                headers: {
                    "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
                }
            })
            .then(response => { 
                alert("Email enviado com sucesso!");
                document.formOrcamento.reset();
            }).catch(error => {
                alert("Ops. Algo deu errado, por favor, reinicie a página e tente novamente: " + error)
            });
    }

    function handleFormSubmit(event) {
        event.preventDefault();
        send(campos);
    }

    return (
        <div className='col-sm-6'>
            <form onSubmit={handleFormSubmit} name='formOrcamento'>
                <select className='selectContato' name='unidade' onChange={handleInputChange} required>
                    <option value="">Deseja ser atendido por qual unidade?*</option>
                    <option value="Pontes e Lacerda - MT">Pontes e Lacerda - MT</option>
                    <option value="Vilhena - RO">Vilhena - RO</option>
                </select>
                <div className='row'>
                    <div className='col-sm-6 form-group'>
                        <input className='form-control' id='nome' name='nome' placeholder='Nome Completo*' type='text' required onChange={handleInputChange} />
                    </div>
                    <div className='col-sm-6 form-group'>
                        <input className='form-control' id='email' name='email' placeholder='Email*' type='email' required onChange={handleInputChange} />
                    </div>
                    <div className='col-sm-6 form-group'>
                        <InputMask  className='form-control' id='celular' name='celular' placeholder='Celular*' type='text' required onChange={handleInputChange} mask="(99) 9 9999-9999" maskChar=" " />
                    </div>
                    <div className='col-sm-6 form-group'>
                        <input className='form-control' id='cidade' name='cidade' placeholder='Cidade*' type='text' required onChange={handleInputChange} />
                    </div>
                </div>
                <textarea className='form-control' id='comentario' name='comentario' placeholder='Comentário*' rows='5' onChange={handleInputChange} ></textarea><br />
                <div className='row'>
                    <div className='col-sm-12 form-group'>
                        <button className='btn btn-default pull-right' type='submit'>Enviar</button>
                    </div>
                    <br />
                    <br />
                </div>
            </form>
        </div>
    );
}

export default FormularioContato;