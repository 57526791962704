import React, { Component } from 'react'
import fbConfig from './../fbConfig'
import ItemPortfolio from './ItemPortfolio'
import Slider from "react-slick";
import Depoimento from './Depoimento';

var numero = 4
var largura = window.innerWidth

function controleNumero() {
    if (largura < 450) {
        numero = 1
    } else if (largura > 450 && largura < 650) {
        numero = 2
    } else if (largura > 650 && largura < 850) {
        numero = 3
    } else if (largura > 850 && largura < 1050) {
        numero = 3
    } else if (largura > 1050) {
        numero = 3
    }

}

class Portfolio extends Component {
    constructor(props) {
        super(props)

        controleNumero()


        this.state = {
            portfolio: {

            }
        };

        fbConfig.syncState('portfolio', {
            context: this,
            state: 'portfolio',
            asArray: false
        })
    }

    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: numero,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            pauseOnHover: true
        };
        return (
            <div>

                {/* Container (Portfolio Section) */}
                <div id='portfolio' className='container-fluid text-center'>
                    <div className='container'>
                        <h2>Conheça nossos trabalhos</h2>
                        <div className='sliderPortfolio' >
                            <Slider {...settings}>
                                <div className='card-service'>
                                    <div className="card portfolioCards" style={{
                                        backgroundImage: "url('../imagens/portfolio/FOTOS_1-Magalhães-fotos-3.png')",
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                                        <div className="card-body" >
                                            <h5 className="card-title">Instalação Comercial</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-service'>
                                    <div className="card portfolioCards" style={{
                                        backgroundImage: "url('../imagens/portfolio/FOTOS_2-Magalhães-fotos-8.png')",
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'
                                    }}>

                                        <div className="card-body">
                                            <h5 className="card-title">Instalação Residencial</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-service'>
                                    <div className="card portfolioCards" style={{
                                        backgroundImage: "url('../imagens/portfolio/FOTO-3-Magalhães-fotos-20.png')",
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                                        <div className="card-body">
                                            <h5 className="card-title">Alfa Contabilidade</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-service'>
                                    <div className="card portfolioCards" style={{
                                        backgroundImage: "url('../imagens/portfolio/FOTOS_4-Casa-do-Produtor-foto-6.png')",
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                                        <div className="card-body">
                                            <h5 className="card-title">Casa do Produtor</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-service'>
                                    <div className="card portfolioCards" style={{
                                        backgroundImage: "url('../imagens/portfolio/FOTOS_5-Matecnorte-4.png')",
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                                        <div className="card-body">
                                            <h5 className="card-title">Matecnorte</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-service'>
                                    <div className="card portfolioCards" style={{
                                        backgroundImage: "url('../imagens/portfolio/FOTOS_6 - Fazenda Novo Horizonte.png')",
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                                        <div className="card-body">
                                            <h5 className="card-title">Fazenda Novo Horizonte</h5>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>

                    </div><br /><br />
                    <Depoimento />
                    <h2>Nossos Clientes</h2><br />

                    <div className='row text-center '>
                        {
                            Object.keys(this.state.portfolio)
                                .map(key => {
                                    return <ItemPortfolio key={key} conteudo={this.state.portfolio[key]} />
                                })
                        }

                    </div><br />


                </div>
                
            </div>
        )
    }
}


export default Portfolio