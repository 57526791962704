import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

class Inicio extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nomeBotao: 'glyphicon glyphicon-plus img-circle btn-icon',
            classeDiv: 'hide animate__animated animate__backOutDown a'
        }
    }

    alterarEstado() {
        var Estado
        var NomeBotao
        if (this.state.classeDiv === 'show animate__animated animate__backInUp a') {
            Estado = 'hide animate__animated animate__backOutDown a'
            NomeBotao = 'glyphicon glyphicon-plus img-circle btn-icon'
        } else {
            Estado = 'show animate__animated animate__backInUp a'
            NomeBotao = 'glyphicon glyphicon-minus img-circle btn-icon'
        }
        this.setState({
            nomeBotao: NomeBotao,
            classeDiv: Estado
        })
    }
    render() {
        return (
            <div>
                {/* Container (About Section) */}
                <div id='about' className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-8'>
                            <h2>Sobre nós</h2><br />
                            <h3><b>Somos uma Empresa de Engenharia Elétrica, que atua nas áreas de Energia Solar, Elétrica Industrial e Residencial abrangendo os polos de Consultoria, Projeto, Instalação e Manutenção.</b></h3><br />
                            <div className='textoInicio'>
                                <p>A Magalhães Engenharia tem sede em Pontes e Lacerda - MT e filial em Vilhena - RO, com isso a empresa atende toda a região com a mais moderna e completa linha de produtos e serviços para Energia Solar e Automação Industrial, dispondo de equipe técnica qualificada para atuar no projeto, instalação, operação e manutenção dos mais variados e complexos sistemas.</p>
                                <a className='btn btn-custom' onClick={this.alterarEstado.bind(this)}>
                                    <span className={this.state.nomeBotao}></span>
                                </a>
                                <div className={'AreaTexto ' + this.state.classeDiv} >
                                    <p>A fundação da Magalhães Engenharia aconteceu em 2017 como prestadora de serviço em Engenharia Elétrica. Pouco tempo depois se tornou parceira comercial da WEG S/A, empresa pioneira no fornecimento de soluções em Energias Renováveis no Brasil. Com isso a Magalhães Engenharia aliou seu quadro técnico profissional a experiência em tecnologia de Energia Solar da WEG para fornecer soluções completas em Sistemas Fotovoltaicos, fornecendo equipamentos e serviços em todos os níveis de complexidade da Geração Distribuída Fotovoltaica.</p>
                                    <p>Por se tratar de produtos de alta tecnologia os sistemas de Energia Solar Fotovoltaica precisam ser minuciosamente calculados e executados para garantir longevidade e segurança, para isso, a Magalhães Engenharia mantem em seu quadro técnico profissionais com amplos conhecimentos em sistemas elétricos e fotovoltaicos, acumulando diversos projetos homologados e em funcionamento nos estados de Mato Grosso e Rondônia, com resultados evoluindo todos os meses.</p>
                                    <br />
                                    <h2>Nossos valores</h2><br />
                                    <div className='valores'>
                                        <Tabs>

                                            <TabList>
                                                <Tab>Missão</Tab>
                                                <Tab>Visão</Tab>
                                                <Tab>Valores</Tab>
                                            </TabList>
                                            <div >
                                                <TabPanel>
                                                    <h4>Oferecer soluções, otimizar processos, garantindo economia e satisfação do cliente.</h4>
                                                </TabPanel>
                                                <TabPanel>
                                                    <h4>Ser referência em nível nacional no desenvolvimento de projetos, consultorias e instalações elétricas. Aumentar a participação de mercado, certificando a melhoria continuada na segurança, garantia dos serviços prestados e na satisfação do cliente.</h4>
                                                </TabPanel>
                                                <TabPanel>
                                                    <h4>Comprometimento; Segurança; Qualidade; Satisfação; Crescimento Contínuo; Ética e Dignidade.</h4>
                                                </TabPanel>
                                            </div>
                                        </Tabs>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <img src="./imagens/Imagem_sobrenos.jpg" alt="Item 1" className='imagemSobreNos' />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Inicio
