import React, { Component } from 'react'

import Rodape from '../componentes/Rodape'
import FormularioOrcamento from './FormularioOrcamento';
import CabecalhoOrcamento from './CabecalhoOrcamento';

class Orcamento extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {

        return (

            <div>
                <CabecalhoOrcamento />
                <FormularioOrcamento />
                <Rodape/>
            </div>
        );
    }

}

export default Orcamento;