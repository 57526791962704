import React, { Component } from 'react'
import { Number, Currency } from "react-intl-number-format/dist/index.js";

const item = value => <Number locale="pt-BR">{value}</Number>
const dinheiro = value => <h2>Economia gerada<Currency locale="pt-BR" currency="BRL">{value}</Currency>por mês para nossos clientes</h2>



class RodapeLoja extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vantagemUm: 0,
            vantagemDois: 0,
            vantagemTres: 0
        };
        this.onEnterViewport = this.onEnterViewport.bind(this);
    }
    onEnterViewport() {
        this.setState({
            vantagemUm: 2771,
            vantagemDois: 1183,
            vantagemTres: 2354013.40
        });
    }

    render() {
        return (
            <footer className='container-fluid text-center bg-grey'>
                <a href='#myPage' title='Para o Topo'>
                    <span className='glyphicon glyphicon-chevron-up'></span>
                </a>
                <p>Copyright © 2020 Magalhães Engenharia Elétrica. Todos os direitos reservados. Desenvoldido por <a href='https://www.issidios.com' title='Visite Issidios Tecnologia'>Issidios Tecnologia</a></p>
                <div>Ícones feitos por <a href="https://www.flaticon.com/br/autores/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/br/" title="Flaticon">www.flaticon.com</a></div>
            </footer>
        )
    }
}

export default RodapeLoja