import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Slider from "react-slick";

var numero = 3
var largura = window.innerWidth

function controleNumero() {
    if (largura < 450) {
        numero = 1
    } else if (largura > 450 && largura < 650) {
        numero = 2
    } else if (largura > 650 && largura < 850) {
        numero = 2
    } else if (largura > 850 && largura < 1050) {
        numero = 2
    } else if (largura > 1050) {
        numero = 3
    }

}

class Solar extends Component {
    constructor(props) {
        super(props)
        controleNumero()

    }
    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 2000,
            slidesToShow: numero,
            slidesToScroll: numero,
            autoplay: true,
            autoplaySpeed: 6000,
            pauseOnHover: true
        };
        return (
            <div className='container-fluid text-center bg-grey'>
                <div className='titulo'>
                    <h1><b>Energia Solar</b></h1>
                    <h2>O Sol ao seu favor</h2><br />
                </div>
                <div className='solar container'>
                    <Slider {...settings}>
                        <div className='card-service'>
                            <div className="card solarCards" style={{
                                backgroundImage: "url('../imagens/portfolio/FOTOS_7 - Residencial.png')",
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                            }}>
                                <div className="card-body" >
                                    <h5 className="card-title">Residencial</h5>
                                    <p className="card-text">Reduza sua conta de luz e valorize seu imóvel com Energia Solar</p>
                                </div>
                            </div>
                        </div>
                        <div className='card-service'>
                            <div className="card solarCards" style={{
                                backgroundImage: "url('../imagens/portfolio/FOTOS_4-Casa-do-Produtor-foto-6.png')",
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }}>

                                <div className="card-body">
                                    <h5 className="card-title">Comercial e  industrial</h5>
                                    <p className="card-text">Reduza os custos fixos de seu negócio e aumente sua lucratividade com energia solar</p>
                                </div>
                            </div>
                        </div>
                        <div className='card-service'>
                            <div className="card solarCards" style={{
                                backgroundImage: "url('../imagens/portfolio/FOTOS_8 - Agronegocio.png')",
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }}>
                                <div className="card-body">
                                    <h5 className="card-title">Agronegócio</h5>
                                    <p className="card-text">O agronegócio tem as melhores taxas de financiamento disponíveis no mercado.</p>
                                </div>
                            </div>
                        </div>


                    </Slider><br />
                    <Link className="btn btn-default btnSolar" to='/detalhes'>
                        Saiba Mais
                    </Link>
                </div>

            </div>
        )
    }
}

export default Solar