import React, { Component } from 'react';

class DetalhesLoja extends Component {
    render() {
        return (
            <div>
            </div>
        );
    }
}

export default DetalhesLoja;