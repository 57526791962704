import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import FirebaseService from '../../../servicos/FirebaseServicos';
import { storage } from '../../../fbConfig'

export const privateUrls = {
    edit: {
        name: 'Edit',
        path: '/admin/portfolio-editar/:id',
        pathWithouParam: '/admin/portfolio-editar/'
    }
};

class AdminEditarCadastroPortfolio extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: null,
            titulo: '',
            logo: '',
        };

        this.gravaPortfolio = this.gravaPortfolio.bind(this)

        this.UNSAFE_componentWillMount = () => {
            const { id } = this.props.match.params;
            if (!(id === undefined || !id)) {
                this.setState({ id });
                FirebaseService.getUniqueDataBy('portfolio', id, (data) => this.setState({ titulo: data[1], logo: data[0] }));
            }
        };
    }




    gravaPortfolio(e) {
        var itemPortfolio = {
            titulo: this.titulo.value,
            imagem: this.imagemPortfolio
        }
        if (itemPortfolio.imagem.files[0] === undefined || !itemPortfolio.imagem.files[0]) {
            itemPortfolio = {
                titulo: this.titulo.value,
                imagem: this.state.logo
            }
            try {
                console.log(itemPortfolio)
                FirebaseService.updateData(this.props.match.params.id, 'portfolio', itemPortfolio)
                this.setState({ estaGravando: false, gravado: true })
                alert("Registro alterado com sucesso!")
            } catch (error) {
                alert("Erro ao alterar o registro: " + error)
            }
        } else {
            const itemPortfolioEditar = {
                titulo: this.titulo.value,
                imagem: this.imagemPortfolio
            }
            this.setState({ estaGravando: true, gravado: false })
            const arquivo = itemPortfolioEditar.imagem.files[0]
            const { name } = arquivo
    
            const ref = storage.ref(name)
            ref.put(arquivo)
                .then(img => {
                    img.ref.getDownloadURL()
                        .then(downloadURL => {
                            const alterarPortfolio = {
                                titulo: itemPortfolio.titulo,
                                imagemPortfolio: downloadURL
                            }
                            try {
                                FirebaseService.updateData(this.props.match.params.id, 'portfolio', alterarPortfolio)
                                this.setState({ estaGravando: false, gravado: true })
                                alert("Registro alterado com sucesso!")
                            } catch (error) {
                                alert("Erro ao alterar o registro: " + error)
                            }
    
                        })
                })
        }



    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    render() {
        if (this.state.estaGravando) {
            return (
                <div className='container'>
                    <p><span className='glyphicon glyphicon-refresh'> Aguarde...</span></p>
                </div>
            )
        }
        if (this.state.gravado) {
            return <Redirect to='/admin/portfolio' />
        }
        return (
            <div>
                <div className='bg-grey'>
                    <div className='container '>
                        <h2>Editar Cliente</h2>
                        <form onSubmit={this.gravaPortfolio}>
                            <div className="form-group">
                                <label htmlFor="titulo">Título</label>
                                <input type="text" className="form-control" defaultValue={this.state.titulo} placeholder="Titulo" ref={(ref) => this.titulo = ref} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="imagemPortfolio">Imagem Portfólio</label>
                                <input type="file" className="form-control-file" ref={(ref) => this.imagemPortfolio = ref} />
                            </div>
                            <button type="submit" className="btn btn-primary">Salvar</button>
                            <Link to='/admin/portfolio'>
                                <button className="btn btn-primary btn-cancelar">Cancelar</button>
                            </Link>
                        </form>
                    </div>
                    <br />
                </div>
            </div>
        );
    }
}

export default AdminEditarCadastroPortfolio;