import React, { Component } from 'react';
import Produtos from './Produtos';

class ListaProdutos extends Component {
    state = {
        produtos: [

        ]
    }
    render() {
        return (
            <div id='portfolio' className='container-fluid text-center'>
                <div className='container'>
                    <h2>Página em Manutenção</h2>
                </div>
            </div>
        );
    }
}

export default ListaProdutos;