import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import fbConfig, { storage } from '../../../fbConfig'



class AdminNovoCadastroPortfolio extends Component {
    constructor(props) {
        super(props)

        this.state = {
            estaGravando: false,
            gravado: false,
            data: []
        }
        

        this.gravaPortfolio = this.gravaPortfolio.bind(this)
    }


    gravaPortfolio(e) {
        const itemPortfolio = {
            titulo: this.titulo.value,
            imagem: this.imagemPortfolio
        }
        this.setState({ estaGravando: true, gravado: false })
        const arquivo = itemPortfolio.imagem.files[0]
        const { name } = arquivo

        const ref = storage.ref(name)
        ref.put(arquivo)
            .then(img => {
                img.ref.getDownloadURL()
                    .then(downloadURL => {
                        console.log(downloadURL)
                        const novoPortfolio = {
                            titulo: itemPortfolio.titulo,
                            imagemPortfolio: downloadURL
                        }
                        fbConfig.push('portfolio', {
                            data: novoPortfolio
                        })
                        this.setState({ estaGravando: false, gravado: true })

                    })
            })

        e.preventDefault()

        
        
    }


    render() {
        if (this.state.estaGravando) {
            return (
                <div className='container'>
                    <p><span className='glyphicon glyphicon-refresh'> Aguarde...</span></p>
                </div>
            )
        }
        if (this.state.gravado) {
            alert("Cadastro Realizado com sucesso!");
        }
        return (
            <div className='bg-grey'>
                <div className='container '>
                    <h2>Novo Cliente</h2>
                    <form onSubmit={this.gravaPortfolio}>
                        <div className="form-group">
                            <label htmlFor="titulo">Título</label>
                            <input type="text" className="form-control" id="titulo" placeholder="Titulo" ref={(ref) => this.titulo = ref} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="imagemPortfolio">Imagem Portfólio</label>
                            <input type="file" className="form-control-file" id="imagemPortfolio" ref={(ref) => this.imagemPortfolio = ref} />
                        </div>
                        <button type="submit" className="btn btn-primary">Salvar</button>
                        <Link to='/admin/portfolio'>
                            <button className="btn btn-primary btn-cancelar">Cancelar</button>
                        </Link>
                    </form>
                </div>
                <br />
            </div>
        );
    }
}

export default AdminNovoCadastroPortfolio;