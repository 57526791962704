import React, { useState } from 'react'
import InputMask from 'react-input-mask';
import axios from 'axios'


function FormularioOrcamento() {
    var enviarEmail = '';
    const [campos, setCampos] = useState({
        nome: "",
        email: "",
        celular: "",
        cidade: "",
        unidade: "",
        valorMedio: "",
        anexo: "",
        tipoCliente: "",
    });

    function handleInputChange(event) {
        if (event.target.name === "anexo") {
            if (event.target.files[0].size >= 5000000) {
                alert("Arquivo muito extenso. Por favor, selecione um menor que 5 Mb.")
                event.target.value = null;
            } else {
                campos[event.target.name] = event.target.files[0];
            }

        } else {

            campos[event.target.name] = event.target.value;
            setCampos(campos);

        }
    }

    function verificaEmail() {
        if (campos.unidade === "Pontes e Lacerda - MT") {
            enviarEmail = "enviarOrcamentoLacerda";
        } else {
            enviarEmail = "enviarOrcamentoVilhena";
        }
    }

    function send() {
        verificaEmail();
        const formData = new FormData();
        Object.keys(campos).forEach(key => formData.append(key, campos[key]));
        axios.post('https://backendmagalhaes.herokuapp.com/' + enviarEmail,
            formData,
            {
                headers: {
                    "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
                }
            })
            .then(response => {
                alert("Email enviado com sucesso!")
                document.formOrcamento.reset();
                enviarEmail = "";
            }).catch(error => {
                alert("Ops. Algo deu errado, por favor, reinicie a página e tente novamente: " + error)
            });
    }

    function handleFormSubmit(event) {
        event.preventDefault();

        send(campos);
    }

    return (
        <div id='contact' className='container-fluid bg-grey'>
            <h2 className='text-center'>Requisite um orçamento de Energia Solar</h2>
            <h3 className='text-center'>Responderemos o mais rapido possivel</h3><br />
            <div className='container'>
                <form onSubmit={handleFormSubmit} name='formOrcamento'>
                    <select className='selectContato' name='unidade' onChange={handleInputChange} required>
                        <option value="">Deseja ser atendido por qual unidade?*</option>
                        <option value="Pontes e Lacerda - MT">Pontes e Lacerda - MT</option>
                        <option value="Vilhena - RO">Vilhena - RO</option>
                    </select>
                    <div className='row'>
                        <div className='col-sm-6 form-group'>
                            <input className='form-control' id='nome' name='nome' placeholder='Nome Completo*' type='text' required onChange={handleInputChange} />
                        </div>
                        <div className='col-sm-6 form-group'>
                            <input className='form-control' id='email' name='email' placeholder='Email*' type='email' required onChange={handleInputChange} />
                        </div>
                        <div className='col-sm-6 form-group'>
                            <InputMask className='form-control' id='celular' name='celular' placeholder='Celular*' type='text' required onChange={handleInputChange} mask="(99) 9 9999-9999" maskChar=" " />
                        </div>
                        <div className='col-sm-6 form-group'>
                            <input className='form-control' id='cidade' name='cidade' placeholder='Cidade*' type='text' required onChange={handleInputChange} />
                        </div>
                        <div className='col-sm-6 form-group'>
                            <input className='form-control' id='valorMedio' name='valorMedio' placeholder='Informe o valor médido de gasto com energia - kWh*' type='text' required onChange={handleInputChange} />
                        </div>

                    </div>
                    <select className=' selectOrcamento' name='tipoCliente' onChange={handleInputChange} required>
                        <option value="">Selecione um tipo de local... *</option>
                        <option value="Residencial">Residencial</option>
                        <option value="Comercial">Comercial</option>
                        <option value="Industrial">Industrial</option>
                        <option value="Rural">Rural</option>
                    </select>
                    <br />
                    <br />
                    <div className='faturaEnergia'>
                        <p>Importante! Sua conta de luz tem informações que vão nos ajudar a dimensionar seu sistema solar com mais precisão, agradecemos caso possa anexar uma foto ou cópia no campo abaixo.</p>
                    </div>
                    <label htmlFor="anexo">Fatura da Conta de Luz</label>
                    <input type="file" id="anexo" name="anexo" onChange={handleInputChange} />
                    <br />
                    <div className='row'>
                        <div className='col-sm-12 form-group'>
                            <button className='btn btn-default pull-right' type='submit'>Enviar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default FormularioOrcamento;