import React from 'react'
import { Link } from 'react-router-dom'


const CabecalhoAdmin = props => {
    return (
        <div id='myPage'>

            <nav className='navbar navbar-default navbar-fixed-top'>
                <div className='container'>
                    <div className='navbar-header'>
                        <button type='button' className='navbar-toggle' data-toggle='collapse' data-target='#myNavbar'>
                            <span className='icon-bar'></span>
                            <span className='icon-bar'></span>
                            <span className='icon-bar'></span>
                        </button>
                        <Link to='/' className="navbar-brand">
                            <img src='logo_v.png' alt='' width='340' height='45' />
                        </Link>
                    </div>
                    <div className='collapse navbar-collapse' id='myNavbar'>
                        <ul className='nav navbar-nav navbar-right'>
                            <li><Link to='/'>VOLTAR</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default CabecalhoAdmin