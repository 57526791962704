import React, { Component } from 'react'
import Slider from "react-slick";

class Depoimento extends Component {

    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 6000,
            pauseOnHover: true
        };
        return (

            <div className="container-fluid text-center bg-grey depoimentos">
                <br />
                <h2>Depoimentos</h2>
                <div >
                    <Slider {...settings}>
                        <div>
                            <h3>"Muito satisfatório tanto o atendimento dos profissionais, quanto ao novo momento do consumo, que podemos usufruir da energia a vontade."</h3>
                            <h4><b>Marcia - Acorizal - MT</b></h4>
                        </div>
                        <div>
                            <h3>"Gostei do trabalho, sem incomodação, recebi tudo instalado, foi ótimo os trabalhos."</h3>
                            <h4><b>Vera - Pontes e Lacerda - MT</b></h4>
                        </div>
                        <div>
                            <h3>"Engenheiro e técnicos capacitados! Qualidade e agilidade na instalação! Melhor preço com garantia do Estado!"</h3>
                            <h4><b>Fernanda - Pontes e Lacerda - MT</b></h4>
                        </div>
                    </Slider>
                </div>
            </div>
        )
    }
}

export default Depoimento;