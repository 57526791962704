import React, { Component } from 'react'


import FirebaseService from '../../../servicos/FirebaseServicos'
import { DataTabelaAdmin } from './DataTabelaAdmin'

class AdminPortfolio extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: []
        }

    }
    componentDidMount() {
        FirebaseService.getDataList('portfolio', (dataReceived) => this.setState({data: dataReceived}))
    }

    render() {
        
        return (
            <div className='container-fluid bg-grey' id='section2'>
                <DataTabelaAdmin data={this.state.data} />
            </div>
        )
    }
}

export default AdminPortfolio