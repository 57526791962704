import React, { Component } from 'react';
import CabecalhoLoja from './componentes/CabecalhoLoja';
import Carrinho from './componentes/Carrinho';
import DetalhesLoja from './componentes/DetalhesLoja';
import ListaProdutos from './componentes/ListaProdutos';
import RodapeLoja from './componentes/RodapeLoja';
import { BrowserRouter, Route } from 'react-router-dom'
import Padrao from './componentes/Padrao';

class Loja extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div>
                <CabecalhoLoja />
                <BrowserRouter>
                    <Route path='/loja/' exact component={ListaProdutos} />
                    <Route path='/loja/detalhes-loja' component={DetalhesLoja} />
                    <Route path='/loja/carrinho' component={Carrinho} />
                    <Route component={Padrao} />
                </BrowserRouter>
                <RodapeLoja />
            </div>
        );
    }
}

export default Loja;