import { firebaseDatabase } from '../fbConfig'

export default class FirebaseService {
    static getDataList = (nodePath, callback, size = 20) => {

        let query = firebaseDatabase.ref(nodePath)
            .limitToLast(size);
        query.on('value', dataSnapshot => {
            let items = [];
            dataSnapshot.forEach(childSnapshot => {
                let item = childSnapshot.val();
                item['key'] = childSnapshot.key;
                items.push(item);
            });
            callback(items);
        });

        return query;
    };

    static getUniqueDataBy = (nodePath, id, callback) => {

        let query = firebaseDatabase.ref(nodePath + '/' + id);
        query.on('value', data => {
            let items = [];
            data.forEach(childSnapshot => {
                let item = childSnapshot.val();
                items.push(item);
            });
            callback(items);
        });

        return query;
    };

    static updateData = (id, node, obj) => {
        console.log(obj)
        let query = firebaseDatabase.ref(node + '/' + id).set({...obj});

        return query;
    };

    static remove = (id, node) => {
        return firebaseDatabase.ref(node + '/' + id).remove();
    };

}