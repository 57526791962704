import React, { Component } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        sidebar: state.Detalhes.sidebar
    };
}


const Retorno = ({ sidebar }) => {

    const isOn = sidebar
    return (
        <div>
            <div id='retorno' className={isOn ? 'container-fluid bg-grey page-content p-5 content' : 'container-fluid bg-grey page-content p-5 content active'}>
                <div className='row'>
                    <div className='col-lg-7'>
                        <h2>Retorno sobre o investimento em energia solar</h2><br />
                        <h3><b>Vimos diversas informações sobre energia solar. Entretanto, ainda falta responder uma das grandes dúvidas sobre o assunto: investir em energia solar é um bom negócio quando analisamos o aspecto financeiro?</b></h3><br />
                        <div className='comoFuncionaDetalhes'>
                            <p>Do lado das despesas, você tem os equipamentos necessários, a instalação e manutenção do sistema de energia solar. Já do lado dos ganhos, você economiza nos gastos com energia elétrica e pode obter créditos para os meses seguintes. Será que os ganhos são superiores aos gastos? Como funciona o retorno sobre o investimento em energia solar? Para fazer um cálculo aproximado do retorno sobre o investimento da energia fotovoltaica é preciso levar em conta os parâmetros que influenciam o seu preço.</p>
                            <li><b>Consumo de energia solar:</b></li>
                            <p>A primeira definição a ser feita é a quantidade de energia que a sua residência ou comércio pretende produzir. Você deseja suprir todo o consumo mensal ou apenas parte dele? É com base nisso que você conseguirá estimar uma média de gastos mensais e poderá ter uma ideia da dimensão necessária do seu sistema. </p>
                            <li><b>Incidência solar da região:</b></li>
                            <p>Outro fator que determina a dimensão do sistema é a incidência solar na região onde se situa a propriedade. Quanto mais incidência solar, mais energia será produzida. Nossa equipe será capaz de calcular a incidência solar no seu projeto e quantos módulos serão necessários.</p>
                            <li><b>Principais custos</b></li>
                            <p>Agora que você tem uma noção mais aproximada de quanto pode economizar mensalmente, vamos ver quais são os principais custos envolvidos no processo.</p>
                            <li><b>Equipamentos</b></li>
                            <p>Além dos custos dos painéis fotovoltaicos, devem ser somados os demais equipamentos - como o inversor solar, cabos e estrutura de instalação, além dos custos de projeto e instalação.</p>
                            <p>Um projeto garante que seu sistema tenha mais desempenho e com isso gere mais economia. Além disso, assegura uma instalação eficiente capaz de prevenir custos adicionais e problemas com manutenções futuras. Neste caso, o velho ditado é válido: “o barato pode sair caro”! Já o valor da instalação é referente aos custos dos serviços técnicos de montagem, configuração e comissionamento (testes de qualidade) do sistema solar.</p>
                            <p>Com o valor total do gasto, é hora de calcular a economia que a energia solar irá gerar para o seu negócio ou sua residência.</p>
                            <li><b>Valor da economia </b></li>
                            <p>E então, qual é o valor total da economia ao utilizar um sistema de energia solar? Para fazer este cálculo é preciso considerar a vida útil do sistema. Os módulos fotovoltaicos possuem garantia de geração de 25 anos, com perda máxima de 20% da sua capacidade inicial. No entanto, estes equipamentos tendem a funcionar por ainda mais tempo. Para calcular a economia mensal, deve-se considerar os dois fatores já determinados: o tamanho do seu sistema e a taxa de radiação solar da sua região. É importante ressaltar também que, com base nos estudos e dimensionamento prévio, um sistema de energia solar pode gerar uma economia de 95% da conta de luz, sendo que os 5% restantes correspondem à taxa mínima cobrada pela concessionária de energia, referente à disponibilidade de conexão com a rede convencional e à taxa de iluminação pública.</p>
                            <li><b>Cálculo do tempo de retorno </b></li>
                            <p>Com o custo do sistema e a economia gerada por ele, você pode calcular o tempo de retorno do investimento. Para isso, basta dividir esses custos pela economia gerada. No geral, o retorno sobre o investimento em energia solar gira em torno de três anos para clientes de baixa tensão e oito anos para clientes de alta tensão. Para saber mais sobre o custo da energia solar fotovoltaico e seu tempo de retorno.</p>
                            <p>Aprenda agora como calcular! A energia solar representa um ótimo investimento para consumidores que pretendem reduzir custos e se tornar autossuficiente na produção de energia. Optar por uma solução completa, que vai desde o projeto até a assistência na manutenção, está cada vez mais acessível financeiramente e pode garantir ao produtor de energia solar os melhores resultados com retornos mais rápidos.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(
    mapStateToProps,
)(Retorno);