import React, { Component } from 'react'
import Slider from "react-slick";

var numero = 3
var largura = window.innerWidth

function controleNumero() {
    if (largura < 450) {
        numero = 1
    } else if (largura > 450 && largura < 650) {
        numero = 2
    } else if (largura > 650 && largura < 850) {
        numero = 3
    } else if (largura > 850 && largura < 1050) {
        numero = 3
    } else if (largura > 1050) {
        numero = 3
    }

}

class Servicos extends Component {
    constructor(props) {
        super(props)
        controleNumero()

    }
    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 2000,
            slidesToShow: numero,
            slidesToScroll: numero,
            autoplay: true,
            autoplaySpeed: 6000,
            pauseOnHover: true
        };
        return (

            < div id='services' className='container-fluid text-center bg-grey' >
                <h2>SERVIÇOS</h2>
                <h4>Estamos sempre em busca de aperfeiçoamento dos nossos serviços, gerando confiança e credibilidade com a execução de projetos qualificados em tempo reduzido.</h4>

                <Slider {...settings}>
                    <div className='card-service'>
                        <div className="card servicosCards" >
                            <img src="./imagens/servico/AdequacaoSistemasEletricos.jpg" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Adequação de Sistemas Elétricos para Atendimento a NR-10</h5>


                            </div>
                        </div>
                    </div>
                    <div className='card-service'>
                        <div className="card servicosCards" >
                            <img src="./imagens/servico/CentraisControleMaquinas.jpg" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Montagem de Centrais de Controle de Máquinas para todos os Portes</h5>


                            </div>
                        </div>
                    </div>
                    <div className='card-service'>
                        <div className="card servicosCards" >
                            <img src="./imagens/servico/InstalacoesMotoresPequenoMedioPorte.jpg" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Instalações de Motores de Pequeno e Médio Porte</h5>


                            </div>
                        </div>
                    </div>
                    <div className='card-service'>
                        <div className="card servicosCards" >
                            <img src="./imagens/servico/MontagemCentraisControleMaquinas.jpg" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Montagem de Centrais de Controle de Máquinas para todos os Portes</h5>

                            </div>
                        </div>
                    </div>
                    <div className='card-service'>
                        <div className="card servicosCards" >
                            <img src="./imagens/servico/MontagemIluminacao.jpg" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Montagem de iluminação de Médio e Grande Porte</h5>

                            </div>
                        </div>
                    </div>
                    <div className='card-service'>
                        <div className="card servicosCards" >
                            <img src="./imagens/servico/MontagensTubulacoesEletricasEletrodutos.jpg" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Montagens de Tubulações Elétricas em Eletrodutos Galvanizados e PVC</h5>
                            </div>
                        </div>
                    </div>
                    <div className='card-service'>
                        <div className="card servicosCards" >
                            <img src="./imagens/servico/InspeçoesMedicoes.jpg" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Inspeções, Medições e Laudos Termográficos</h5>
                            </div>
                        </div>
                    </div>
                    <div className='card-service'>
                        <div className="card servicosCards" >
                            <img src="./imagens/servico/InstalacaoLinhasBaixaAltaTensao.jpg" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Projeto, Manutenção e Instalação de Linhas de Baixa e Alta Tensão</h5>
                            </div>
                        </div>
                    </div>
                    <div className='card-service'>
                        <div className="card servicosCards" >
                            <img src="./imagens/servico/InstalaçaoSistemasAterramentoSPDA.jpg" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Projetos, Medições, Laudos, Adquações, Instalações de Sistemas de Aterramento e SPDA</h5>
                            </div>
                        </div>
                    </div>
                    <div className='card-service'>
                        <div className="card servicosCards" >
                            <img src="./imagens/servico/AutomacaoSistemas.jpg" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Automação de sistemas , Montagem e Instalação de C.L.P.</h5>
                            </div>
                        </div>
                    </div>
                    <div className='card-service'>
                        <div className="card servicosCards" >
                            <img src="./imagens/servico/CalculoCarga.jpg" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Elaboração, Dimensionamento, Cálculo de carga, Montagem e Instalação de Quadro de distribuição</h5>
                            </div>
                        </div>
                    </div>
                    <div className='card-service'>
                        <div className="card servicosCards" >
                            <img src="./imagens/servico/ProjetoDimensionamentoFatorPotencia.jpg" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Análise para correção, Projeto e Dimensionamento de Fator de Potência</h5>
                            </div>
                        </div>
                    </div>
                    <div className='card-service'>
                        <div className="card servicosCards" >
                            <img src="./imagens/servico/ProjetoEletrico.jpg" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Desenvolvimento de Projetos em Sistemas Elétricos</h5>
                            </div>
                        </div>
                    </div>
                    <div className='card-service'>
                        <div className="card servicosCards" >
                            <img src="./imagens/servico/SistemasControleDemandaCoordenogramaSeletividade.jpg" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Instalação de Sistemas para Controle de Demanda Coordenograma de Seletividade, Laudos e Medições</h5>
                            </div>
                        </div>
                    </div>
                    <div className='card-service'>
                        <div className="card servicosCards" >
                            <img src="./imagens/servico/SubestacaoMedioPequenoPorte.jpg" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Montagem de Subestações de Médio e Pequeno Porte</h5>
                            </div>
                        </div>
                    </div>
                    <div className='card-service'>
                        <div className="card servicosCards" >
                            <img src="./imagens/servico/treinamento.jpg" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Treinamentos</h5>
                            </div>
                        </div>
                    </div>
                    <div className='card-service'>
                        <div className="card servicosCards" >
                            <img src="./imagens/servico/SistemaFotovoltaico.jpg" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Sistema Fotovoltaico</h5>
                            </div>
                        </div>
                    </div>
                </Slider>

            </div>
        )
    }
}



export default Servicos