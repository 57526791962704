import React, { Component } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        sidebar: state.Detalhes.sidebar
    };
}

const Seguro = ({ sidebar }) => {

    const isOn = sidebar
        return (
            <div>
                <div id='seguro' className={isOn ? 'container-fluid  page-content p-5 content' : 'container-fluid  page-content p-5 content active'}>
                    <div className='row'>

                        <div className='col-lg-7'>
                            <h2>Seguros</h2><br />
                            <h3><b>Risco Engenharia Resp. Civil Obras</b></h3><br />

                            <div className='comoFuncionaDetalhes'>
                                <p>Principais Coberturas</p>
                                <li>Incêndio - Explosões;</li>
                                <li>Roubo e ou Furto qualificado;</li>
                                <li>Inundação e Alagamento;</li>
                                <li>Vendaval - Granizo - Raio;</li>
                                <li>Danos Elétricos;</li>
                                <li>Erro de Projeto;</li>
                                <li>Manutenção Ampla - até 6 meses;</li>
                                <li>Despesas de Desentulho;</li>
                                <li>Despesas Extraordinárias;</li>
                                <li>Tumultos - Greve;</li>
                                <li>Honorários de Peritos;</li>
                                <li>Resp. Civil Geral - Cruzado;</li>
                                <li>Danos Morais;</li>
                                <li>Lucros Cessantes;</li>
                                <li>Resp. Civil Empregador;</li>
                                <li>Propriedades Circunvizinhas;</li>
                                <li>Equip. Móveis/Estacionários;</li>
                                <li>Ferramentas de Pequeno Porte.</li>
                                <br />
                                <p>Cobertura durante o período de <b>Montagem e Instalação</b>, até a data de ligação do sistema na rede da concessionária de <b>Energia</b>.</p>
                            </div>
                            <div className='col-lg-6'>
                                <img src="./imagens/logo/Dual Seguros.png" alt="Item 1" className='imagemDetalhes' />
                                <p><b>SUSEP Nª 202034821</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default connect(
    mapStateToProps,
)(Seguro);