import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';


const CabecalhoDetalhes = ({sidebar}) => {
    
        const isOn = sidebar
        return (
            <div id='myPage'>

                <div className={isOn ? "vertical-nav bg-white" : "vertical-nav bg-white active"} id="sidebar">
                    <div className="py-4 px-3 mb-4 bg-light">
                        <div className="media d-flex align-items-center">
                            <Link to='/'>
                                <img src="./imagens/logo/logo_192.png" alt="..." width="65" className="mr-3 rounded-circle img-thumbnail shadow-sm" />
                            </Link>
                        </div>
                    </div>

                    <ul className="nav flex-column navbar-nav">
                        <li className="nav-item">
                            <a href="#function" className="nav-link text-dark font-italic bg-light">COMO FUNCIONA</a>
                        </li>
                        <li className="nav-item">
                            <a href="#manutencao" className="nav-link text-dark font-italic">MANUTENÇÃO</a>
                        </li>
                        <li className="nav-item">
                            <a href="#monitoramento" className="nav-link text-dark font-italic">MONITORAMENTO </a>
                        </li>
                        <li className="nav-item">
                            <a href="#legislacao" className="nav-link text-dark font-italic">LEGISLAÇÃO</a>
                        </li>
                        <li className="nav-item">
                            <a href="#retorno" className="nav-link text-dark font-italic">RETORNO DO INVESTIMENTO</a>
                        </li>
                        <li><Link to='/'>VOLTAR</Link></li>
                    </ul>

                </div>
            </div>
        )
}

function mapStateToProps(state) {
    return {
        sidebar: state.Detalhes.sidebar
    };
}

export default connect(mapStateToProps)(CabecalhoDetalhes)