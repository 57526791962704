import React from 'react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { privateUrlsConquista } from './AdminEditarCadastroConquistas';

export const DataTabelaAdmin = ({ data }) => {


    return <React.Fragment>
        <Typography variant="h3" component="h2" className='col-sm-5'>Conquistas - Área Administrativa</Typography>
        <br/>
        <br/>
        <Table selectable="false">
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Titulo</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Ações</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    data.map((item, index) =>
                        <TableRow key={index}>
                            <TableCell>{item.key}</TableCell>
                            <TableCell>{item.titulo}</TableCell>
                            <TableCell>{item.valor}</TableCell>
                            <TableCell>
                                <Button component={React.forwardRef((props, ref) => <Link to={privateUrlsConquista.edit.pathWithouParam + item.key} 
    {...props} />)}>
                                    EDITAR
                                </Button>
                            </TableCell>
                        </TableRow>
                    )
                }
            </TableBody>
        </Table>
    </React.Fragment>
};