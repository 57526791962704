import React from 'react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { privateUrls } from './AdminEditarCadastroPortfolio';
import FirebaseService from '../../../servicos/FirebaseServicos';

export const DataTabelaAdmin = ({ data }) => {

    const remove = (id) => {
        FirebaseService.remove(id, 'portfolio');
    };

    return <React.Fragment>
        <Typography variant="h3" component="h2" className='col-sm-5'>Clientes - Área Administrativa</Typography>
        
        <Link to='/admin/portfolio-novo'>
            <span className='glyphicon glyphicon-plus img-circle btn-icon'></span>
        </Link>
        <br/>
        <br/>
        <Table selectable="false">
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Logo</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Ações</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    data.map((item, index) =>
                        <TableRow key={index}>
                            <TableCell>{item.key}</TableCell>
                            <TableCell>
                                <img src={item.imagemPortfolio} alt='' width='50px' height='20px' />

                            </TableCell>
                            <TableCell>{item.titulo}</TableCell>
                            <TableCell>
                                <Button
                                    onClick={() => remove(item.key)}>
                                    REMOVER
                                </Button>
                                <Button component={React.forwardRef((props, ref) => <Link to={privateUrls.edit.pathWithouParam + item.key} 
    {...props} />)}>
                                    EDITAR
                                </Button>
                            </TableCell>
                        </TableRow>
                    )
                }
            </TableBody>
        </Table>
    </React.Fragment>
};