import React, { Component } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        sidebar: state.Detalhes.sidebar
    };
}
const Manutencao = ({sidebar}) => {
    
    const isOn = sidebar
        return (
            <div>
                <div id='manutencao' className={isOn ? 'container-fluid  page-content p-5 content' : 'container-fluid  page-content p-5 content active'}>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <h2>Manutenção dos equipamentos</h2><br />
                            <h3><b>A manutenção dos módulos de energia solar é fundamental para que os resultados obtidos sejam satisfatórios. Quando os equipamentos não estão em boas condições, pode ocorrer o comprometimento geração de energia.</b></h3><br />
                            <div className='comoFuncionaDetalhes'>
                                <p>Um item importante é a limpeza dos módulos fotovoltaicos, é possível que ocorra uma grande perda de geração por conta de sujeiras, como poeira ou outros resíduos. Por isso, a limpeza é ponto fundamental na manutenção preventiva.</p>
                                <p>No entanto, é sempre importante lembrar que estamos trabalhando com eletricidade, certo? Qualquer intervenção demanda certos cuidados para evitar acidentes. Pensando nisso, profissionais especializados em energia solar desenvolvem projetos seguros e que favorecem a automanutenção do sistema. Assim, a manutenção dos painéis é muito mais simples do que pode parecer.</p>
                                <p> Além da preocupação para deixar os módulos solares limpos e eficientes, podem surgir situações inesperadas que causam comportamentos anormais do sistema. Nesses casos é indispensável o suporte técnico especializado.</p>
                                <img src="./imagens/detalhes/6-Com-o-limpador-de-placas-fotovoltaicas-a-limpeza-pode-ser-feita-em-solo.jpg" alt="Item 1" className='imagemDetalhes' />
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default connect(
    mapStateToProps,
)(Manutencao);