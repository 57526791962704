import React, { Component } from 'react'
import CountTo from 'react-count-to';
import { Number, Currency } from "react-intl-number-format/dist/index.js";
import ScrollTrigger from 'react-scroll-trigger';
import FirebaseService from '../servicos/FirebaseServicos';

const item = value => <Number locale="pt-BR">{value}</Number>
const dinheiro = value => <h2>Economia gerada<Currency locale="pt-BR" currency="BRL">{value}</Currency>por mês para nossos clientes</h2>



class Rodape extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vantagemUm: 0,
            vantagemDois: 0,
            vantagemTres: 0,
            data: []
        };
        this.onEnterViewport = this.onEnterViewport.bind(this);
    }


    onEnterViewport() {
        console.log(this.state)
        FirebaseService.getDataList('conquistas', (dataReceived) => this.setState({data: dataReceived}, () => this.setState({
            vantagemUm: parseFloat(this.state.data[0].valor),
            vantagemDois: parseFloat(this.state.data[1].valor),
            vantagemTres: parseFloat(this.state.data[2].valor)
        })))
    }

    

    render() {
        return (
            <footer className='container-fluid text-center bg-grey'>
                <ScrollTrigger onEnter={this.onEnterViewport}>
                    <a href='#myPage' title='Para o Topo'>
                        <span className='glyphicon glyphicon-chevron-up'></span>
                    </a>
                    <div className="vantagens">
                        <div className="row invisivel vantagemItem text-center">
                            <div className="col-sm-4 vantagemItemUm">
                                <img src="./imagens/cabecalho/023-solar energy.png" alt="Item 1" className='iconeConquistas' />
                            </div>
                            <div className="col-sm-4 vantagemTexto">
                                <p className='vanUmTitulo'>+ de</p>
                                <b className='vanUmValor'><CountTo from={0} to={this.state.vantagemUm} speed={1800}>{item}</CountTo> <p>kWp</p></b>
                                <p className='vanUmUnidade'>Instalados</p>
                            </div>
                            <div className="col-sm-4 vantagemItemUm">
                                <img src="./imagens/cabecalho/003-rainforest.png" alt="Item 1" className='iconeConquistas' />
                            </div>
                            <div className="col-sm-4 vantagemTexto">
                                <b className='vanUmValor'><CountTo from={0} to={this.state.vantagemDois} speed={1800} className='vanUmValor'>{item}</CountTo></b>
                                <p className='vanUmUnidade'>Arvores cultivadas</p>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='valorRodape '>
                        <h2></h2>
                        <div>
                            <b><CountTo digits={2} from={0.00} to={this.state.vantagemTres} speed={2500} >{dinheiro}</CountTo></b>
                        </div>
                        <p> </p>
                    </div>
                    <br />
                    <p>Copyright © 2020 Magalhães Engenharia Elétrica. Todos os direitos reservados. Desenvoldido por <a href='https://www.issidios.com' title='Visite Issidios Tecnologia'>Issidios Tecnologia</a></p>
                    <div>Ícones feitos por <a href="https://www.flaticon.com/br/autores/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/br/" title="Flaticon">www.flaticon.com</a></div>
                </ScrollTrigger>
            </footer>
        )
    }
}

export default Rodape