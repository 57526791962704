import React, { Component } from 'react'

import Rodape from '../componentes/Rodape'
import CabecalhoDetalhes from './CabecalhoDetalhes';
import EnergiaSolar from './EnergiaSolar'
import ComoFunciona from './ComoFunciona'
import Manutencao from './Manutencao'
import Monitoramento from './Monitoramento'
import Legislacao from './Legislacao'
import Seguro from './Seguro'
import Retorno from './Retorno';
import { Provider } from 'react-redux';
import store from '../store'

class Detalhes extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {

        return (

            <div >
                <Provider store={store}>
                    <CabecalhoDetalhes />
                    <EnergiaSolar />
                    <ComoFunciona />
                    <Manutencao />
                    <Monitoramento />
                    <Legislacao />
                    <Retorno />
                    <Seguro />
                </Provider>
            </div>
        );
    }

}

export default Detalhes;
